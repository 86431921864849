/* DiagnosisCard.css */

.p-card .p-card-body {
  padding: 1.25rem;
  padding-bottom: 0px;
  padding-top: 0px;
}

.container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  font-family: "Google Sans", sans-serif;
  flex-wrap: wrap;
  justify-content: space-between;
}

.icd-10-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  padding-bottom: 10px;
}

.card {
  width: calc(100% - 10px);
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  padding-bottom: 8px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item {
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  word-wrap: break-word;
}

.code-wrapper {
  display: inline-block;
  position: relative;
}

.code {
  font-weight: bold;
  margin-right: 10px;
  color: #007bff;
  display: inline-block;
  cursor: pointer;
}

.description {
  margin-top: 5px;
}

.error-container {
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 15px;
  margin: 20px auto;
  max-width: 800px;
}

.code-info-container {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  /* Set width to 90% of its container */
  max-width: 500px;
  /* Maximum width of 400px */
  min-width: 350px;
  /* Minimum width to ensure readability */
  margin-top: 0;
}

.code-info-card h3 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 10px;
}

.info-field {
  margin-bottom: 8px;
}

.info-field strong {
  display: block;
  margin-bottom: 2px;
}

.info-field ul {
  margin: 0;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
  }

  .title {
    font-size: 20px;
  }

  .code-info-container {
    position: fixed;
    top: auto;
    left: 0;
    bottom: 0;
    transform: none;
    width: 100%;
    max-width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }

  .code-wrapper {
    position: static;
    display: block;
  }

  .list-item {
    position: relative;
    padding-right: 20px;
    /* Add some right padding to prevent text from touching the edge */
  }

  .description {
    word-break: break-word;
    /* Allow long words to break and wrap */
  }
}