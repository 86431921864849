.code-info-card {
  font-size: 14px;
  line-height: 1.4;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
}

.card-title {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 10px;
}

.info-field {
  margin-bottom: 8px;
}

.field-label {
  display: block;
  margin-bottom: 2px;
}

.field-list {
  margin: 0;
  padding-left: 20px;
}

.field-value {
  margin: 0;
}
