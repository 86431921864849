/* CPTCodesDisplay.css */

.p-card .p-card-body {
    padding: 1.25rem;
    padding-bottom: 0px;
    padding-top: 0px;
}

.container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    font-family: "Google Sans", sans-serif;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cpt-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    padding-bottom: 10px;
}

.card {
    width: calc(100% - 10px);
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    padding-bottom: 8px;
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.list-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    word-wrap: break-word;
}

.code {
    font-weight: bold;
    margin-right: 10px;
    color: #007bff;
    cursor: pointer;
}

.error-container {
    color: #dc3545;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 15px;
    margin: 20px auto;
    max-width: 800px;
}

@media (max-width: 768px) {
    .card {
        width: 100%;
    }

    .title {
        font-size: 20px;
    }
}